import React from 'react';
import { API } from '../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
//	Badge,
//	Media,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from './@Include/Header';
import Footer from './@Include/Footer';
import StationarySlick from './StationarySlick';
import StationaryItemSlickNew from './StationaryItemSlickNew';
import StationaryTab from './StationaryTab';
//import StationaryColmun from './StationaryColmun';
//import StationaryInfo from './StationaryInfo';
import GetItemInfo from './GetItemInfo';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			ranking1      : '62018',
			ranking2      : '62019',
			ranking3      : '202501',
			ranking4      : '62020',
			ranking5      : '82214',

			cognitoid      :  ''
		}
		this._getData     = this._getData.bind(this);
	}
	async _getData() {
		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					cognitoid  : cognitoid,
				 });
			}
		}
		catch(error) {
			//
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		return (
			<div>
			<Header/>

				<main>
					<div className="head-logo sec">
						<a className="" href="/stationary_index/"><Image src="../svg/logo_header.svg" /></a>
					</div>

	<StationarySlick />

	<Container className="mt-5 pt-5">
<a href="/search/◆2025福袋◆/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202501/bnr_hb2025-02_sp.jpg" />
	 <Image src="/img/202501/bnr_hb2025-02.jpg" alt="福袋" fluid className="bdrimg" />
 </picture></a>
</Container> 
{/* <Container className="mt-5">
	<a href="/search/ウィンターセール/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202411/bnr_sale2411_sp.jpg" />
	 <Image src="/img/202411/bnr_sale2411.jpg" alt="SALE" fluid className="bdrimg" />
 </picture></a>
 <div className="mt-2 pb-1">
 <ul className="flexbox-start list-4">
 <li><a href="/search/ウィンターセール-シール/" className="btn-red w-100 btn">シール</a></li>
 <li><a href="/search/ウィンターセール-テープ/" className="btn-red w-100 btn">テープ</a></li>
 <li><a href="/search/ウィンターセール-ふせん/" className="btn-red w-100 btn">ふせん</a></li>
 <li><a href="/search/ウィンターセール-紙文具/" className="btn-red w-100 btn">紙文具</a></li>
 <li><a href="/search/ウィンターセール-文具小物/" className="btn-red w-100 btn">文具小物</a></li>
 <li><a href="/search/ウィンターセール-筆記用具/" className="btn-red w-100 btn">筆記用具</a></li>
 <li><a href="/search/ウィンターセール-鉛筆・消しゴム/" className="btn-red w-100 btn">鉛筆・消しゴム</a></li>
 <li><a href="/search/ウィンターセール-雑貨/" className="btn-red w-100 btn">雑貨</a></li></ul>
 <ul className="flexbox-start list-3">
 <li><a href="/search/ウィンターセール-20％OFF/" className="btn-red w-100 btn">20％OFF</a></li>
 <li><a href="/search/ウィンターセール-30％OFF/" className="btn-red w-100 btn">30％OFF</a></li>
 <li><a href="/search/ウィンターセール-40％OFF/" className="btn-red w-100 btn">40％OFF</a></li>
 </ul>
</div>
 </Container>*/}
{/*	<Container className="mt-4">
	<picture>
		<source media="(max-width: 767px)" srcSet="/img/202405/bnr_nuicon2405_sp.jpg" />
			<Image src="/img/202405/bnr_nuicon2405.jpg" alt="コンテスト開催" fluid className="bdrimg"/>
		</picture>
		<div className="mt-2 pb-1">
	<ul className="flexbox-start list-2">
 <li><a href="https://www.instagram.com/p/C7DORq9KO4V/" className="btn-cosme w-100 btn">Instagram</a></li>
 <li><a href="https://x.com/mindwaveinc/status/1791262346113147058" className="btn-cosme w-100 btn">X</a></li>
 </ul>
 <ul className="flexbox-start list-3">
 <li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/" className="btn-cosme w-100 btn">にゃんすけ対象商品</a></li>
 <li><a href="/category/キャラクターから探す-しばんばん-ぬいぐるみ/" className="btn-cosme w-100 btn">しばんばん対象商品</a></li>
 <li><a href="/category/キャラクターから探す-ぴよこ豆-ぬいぐるみ/" className="btn-cosme w-100 btn">ぴよこ豆対象商品</a></li>
 </ul>
		 
	</div>
</Container> */}
{/* 
<Container className="mt-3">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202308/bnr_free2308_sp.jpg" />
 <Image src="/img/202308/bnr_free2308.jpg" alt="送料無料キャンペーン" fluid className="bdrimg" />
</picture>
</Container>
 

<Container className="mt-5">
	<a href="/search/ごろごろばんばんマルシェ/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202405/bnr_goroban-m_sp.jpg" />
	 <Image src="/img/202405/bnr_goroban-m.jpg" alt="ごろごろばんばんマルシェ" fluid className="bdrimg" />
 </picture></a>
 <div className="mt-2 pb-1">
 <ul className="flexbox-start list-2">
	<li><a href="/search/ごろごろばんばんマルシェ-ごろごろにゃんすけ/" className="btn-pink w-100 btn">ごろごろにゃんすけ</a></li>
 <li><a href="/search/ごろごろばんばんマルシェ-しばんばん/" className="btn-pink w-100 btn">しばんばん</a></li>
 </ul>
</div>
</Container>


<Container className="mt-3">
		<a href="/search/ごろごろにゃんすけ%2010th%20ANNIVERSARY/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202403/bnr_nyansuke10th_sp.jpg" />
		 <Image src="/img/202403/bnr_nyansuke10th.jpg" alt="ごろごろにゃんすけ" className="bdrimg" fluid />
	 </picture></a> </Container>
	 <Container className="mt-3">
		<a href="/search/スーパー銭湯ぴよこ豆/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202402/bnr_piyoko-sento2024_sp.jpg" />
		 <Image src="/img/202402/bnr_piyoko-sento2024.jpg" alt="スーパー銭湯ぴよこ豆" fluid />
	 </picture></a> </Container>
	 <Container className="mt-5">
<a href="https://images.mindwave-store.com/mz/questionnaire/nenga_present2024_info.html" className="opa06" target="_blank" rel="noopener noreferrer">
<picture>
<source media="(max-width: 767px)" srcSet="/img/2024010/bnr_nenga2024_sp.jpg" />
 <Image src="/img/202410/bnr_nenga2024.jpg" alt="キャンペーン" fluid />
</picture></a>
</Container>*/}
<Container className="mt-3">
		<a href="/search/【2025】干支アクリルジオラマ/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202412/bnr_eto2025_sp.jpg" />
		 <Image src="/img/202412/bnr_eto2025.jpg" alt="キャンペーン" className="bdrimg" fluid />
	 </picture></a> </Container>
<Container className="mt-3">
<picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_sticker_sp.jpg" />
	<Image src="/img/top/bnr_sticker.jpg" alt="書き下ろしステッカープレゼント！" fluid />
</picture>
</Container>
 





{/*
 <Container className="mt-3">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_wrapping-xmas_sp.png" />
				 <Image src="/img/guide/img_wrapping-xmas.png" alt="季節限定ラッピング" className="bdrimg" fluid/>
			 </picture>
</Container>
 */}
{/*
<Container className="mb-5">
	<div className="oshirase text-center">
	<h3 className="red"><strong><i className="fas fa-truck"></i> 大雪や荒天の影響によるお荷物のお届けについて</strong></h3>
	<p>大雪や荒天の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。</p>
<p><strong className="">
<a href="https://www.kuronekoyamato.co.jp/ytc/chien/chien_hp.html"><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/notification/productinformation/2021/0113_01.html"><i className="fas fa-caret-right"></i> 日本郵政</a>
</strong></p>
	</div>
</Container>
*/}
{/*ここに休業案内 */} 
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/info/info_holidays_sp.jpg" />
	 <Image src="/img/info/info_holidays.jpg" alt="休業のご案内" fluid />
</picture>
</Container>

	<h2 className='ttl_en'>NEW ARRIVALS<p>新商品</p>

<p className="text-center"><a href="/category/今週発売/" className="btn btn-blue" size="sm"><i className="fas fa-caret-right"></i> 新商品（1月24日UP）リストはこちら</a></p>
	</h2>


	<StationaryItemSlickNew />
	<Container className="mt-5">
<a href="/guide/#wrapping" className="opa06">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_card_sp.jpg" />
				 <Image src="/img/guide/img_card.jpg" alt="ラッピング" className="bdrimg" fluid/>
			 </picture>
</a>
</Container>
	
	<Container className="mt-3">
<a href="/others/strapcase/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/strapcase/bnr_strapcase_sp.jpg" />
	 <Image src="/img/strapcase/bnr_strapcase.jpg" alt="オンラインストア限定" fluid />
 </picture></a>
</Container>
<Container className="mt-3 pb-5">
<a href="/others/mobileitem/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/mobileitem/bnr_mobileitem_sp.jpg" />
	 <Image src="/img/mobileitem/bnr_mobileitem.jpg" alt="オンラインストア限定モバイルITEM" fluid className="bdrimg"/>
 </picture></a>
</Container>


<Container>
<h3 className="maskingtape">▼ 人気キャラクターの特設ページをCHECK！</h3>
<Row>
 <Col xs={12} sm={6} ><a href="/others/nyansuke/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-nyansuke_sp.jpg" alt="ごろごろにゃんすけ" fluid /></a></Col>
 <Col xs={12} sm={6} ><a href="/others/shibanban/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-banban_sp.jpg" alt="しばんばん" fluid /></a></Col>
 <Col xs={12} sm={6} ><a href="/others/piyokomame/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-piyoko_sp.jpg" alt="ぴよこ豆" fluid /></a></Col>
 <Col xs={12} sm={6} ><a href="/category/キャラクターから探す-うさぎのムーちゃん/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-muchan_sp.jpg" alt="うさぎのムーちゃん" fluid /></a></Col>
 </Row>
<p className="mb-5">
 <a href="/others/30th/" className="opa06"><picture>
 <source media="(max-width: 767px)" srcSet="/img/top/bnr_30th_sp.jpg" />
 <Image src="/img/top/bnr_30th.jpg" alt="30周年特設ページ" fluid />
 </picture></a></p>

				{/*<Row>
					<Col xs={6} sm={{ span: 3, offset: 2 }} ><a href="/item/94735/"><Image src="https://images.mindwave-store.com/public/94735_main.jpg" fluid />
					<h5><GetItemInfo code="94735" setkey="name" /></h5></a><p className="list-price">￥<GetItemInfo code="94735" setkey="price" /><span>（税込）</span></p></Col>
					<Col xs={6} sm={{ span: 3, offset: 2 }}><a href="/item/94736/"><Image src="https://images.mindwave-store.com/public/94736_main.jpg" fluid />
					<h5><GetItemInfo code="94736" setkey="name" /></h5></a><p className="list-price">￥<GetItemInfo code="94736" setkey="price" /><span>（税込）</span></p></Col>
				</Row>*/}

</Container>


			<Container className="top-catch">
			<h2 className='ttl_en'>STANDARD ITEM<p>おすすめ定番カテゴリ</p></h2>
					{/*<h3 className="maskingtape">2020年 この時期におすすめの新商品</h3>*/}
			<Row className="mb-5">
			<Col sm={4}>
			        <Row>
			{/*<Col xs={12} sm={12} className="mb-30px"><a href="/search/招福シール/"><Image src="/img/201910/bnr_winter01.jpg" /></a></Col>
			<Col xs={12} sm={12} className="mb-30px"><a href="/search/サロン ド フルール/"><Image src="/img/202001/bnr_0109_1.png" /></a></Col>*/}
			<Col xs={12} sm={12} className="mb-30px"><a href="/category/アイテムから探す-シール-プチプチシール/"><Image src="/img/top/topcate_puchi2024.jpg" /></a></Col>
			<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-シール-ハートシール/"><Image src="/img/top/topcate_heart2024.jpg" alt="ハートシール" /></a></Col>
			<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-シール-他フレークシール/"><Image src="/img/top/topcate_flake2024.jpg" alt="フレークシール" /></a></Col>
			   </Row>
			</Col>
			<Col sm={4}>
			      <Row>
						{/*<Col xs={6} sm={6} className="mb-30px"><a href="/search/2019年09月始まり/"><Image src="/img/202001/bnr_0109_3.png" /></a></Col>*/}
						<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-レター/"><Image src="/img/top/topcate_letter2024.jpg" alt="レター" /></a></Col>
						<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-メモ/"><Image src="/img/top/topcate_memo2024.jpg" alt="メモ" /></a></Col>
						{/*<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-ダイアリー/"><Image src="/img/202004/recommend_diary.jpg" alt="ダイアリー" /></a></Col>
			<Col xs={12} sm={12} className="mb-30px"><a href="/search/2020年02月始まり%20B6マンスリー%20しばんばんとごろごろにゃんすけ/"><Image src="/img/202001/bnr_0109_2.png" /></a></Col>*/}
			<Col xs={12} sm={12} className="mb-30px"><a href="/category/アイテムから探す-テープ/"><Image src="/img/top/topcate_tape2024.jpg" alt="テープ" /></a></Col>
			   </Row>
			</Col>
			<Col sm={4}>
			      <Row>
			<Col xs={12} sm={12} className="mb-30px"><a href="/category/アイテムから探す-付箋/"><Image src="/img/top/topcate_husen2024.jpg" alt="付箋" /></a></Col>
			<Col xs={6} sm={6} className="mb-30px"><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><Image src="/img/top/topcate_nuigurumi2024.jpg" /></a></Col>
			<Col xs={6} sm={6} className="mb-30px"><a href="/search/OUTLET/"><Image src="/img/top/outletsale.jpg" alt="outlet sale" /></a></Col>
			   </Row>
			</Col>
			</Row>
			</Container>

			<Container className="top-catch pb-5">
			<h2 className='ttl_en'>FEATURED ITEM<p>注目アイテム</p></h2>
			<Row className="no-gutters mb-3">
			<Col xs={12} sm={4} className=""><a href="/search/コウジーステッカー"><Image src="/img/top/featured_11.jpg" alt="コウジーステッカー" /></a></Col>
			<Col xs={12} sm={4} className=""><a href="/search/アミ"><Image src="/img/top/featured_01.jpg" alt="アミステッカー" /></a></Col>
			<Col xs={12} sm={4} className=""><a href="/search/フェリーチェ"><Image src="/img/top/featured_02.jpg" alt="フェリーチェ" /></a></Col>
			<Col xs={12} sm={4} className=""><a href="/search/ブーケステッカー"><Image src="/img/top/featured_03.jpg" alt="ブーケステッカー" /></a></Col>
			<Col xs={12} sm={4} className=""><a href="/search/クリアテープ"><Image src="/img/top/featured_05.jpg" alt="クリアテープ" /></a></Col>
			
			<Col xs={6} sm={4} className=""><a href="/search/ランドスケープステッカー"><Image src="/img/top/featured_04.jpg" alt="ランドスケープステッカー" /></a></Col>
			<Col xs={6} sm={4} className=""><a href="/category/キャラクターから探す-シアー%20フォト%20シリーズ/
"><Image src="/img/top/featured_07.jpg" alt="シアーフォトシリーズ" /></a></Col>
			<Col xs={6} sm={4} className=""><a href="/category/アイテムから探す-付箋-スティックマーカー/
"><Image src="/img/top/featured_08.jpg" alt="スティックマーカー" /></a></Col>
			<Col xs={6} sm={4} className=""><a href="/search/除菌ウェット"><Image src="/img/top/featured_09.jpg" alt="除菌ウェット" /></a></Col>
			
			</Row>
			<Row className="mb-5 pb-5">
			<Col xs={6} sm={3} className="mb-3"><a href="/item/61984/"><Image src="/img/top/top_pu-item91.jpg" alt="オススメITEM" /></a></Col>
			<Col xs={6} sm={3} className="mb-3"><a href="/item/95378/"><Image src="/img/top/top_pu-item58.jpg" alt="オススメITEM" /></a></Col>
			<Col xs={6} sm={3} className="mb-3"><a href="/item/61923/"><Image src="/img/top/top_pu-item87.jpg" alt="オススメITEM" /></a></Col>
			<Col xs={6} sm={3} className="mb-3"><a href="/item/62014/"><Image src="/img/top/top_pu-item95.jpg" alt="オススメITEM" /></a></Col>
			</Row>
			</Container>
	<Container fluid={true} className="bg_gray pb-5 mb-5">
		<Container className="top-catch character pb-5">
			<h2 className='ttl_en'>CHARACTER<p>人気キャラクター</p></h2>
		<Row>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-ごろごろにゃんすけ/"><Image src="/img/top/bnr_nyansuke.png" /></a></Row></Col>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-しばんばん/"><Image src="/img/top/bnr_shibanbaban.png" /></a></Row></Col>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-ぴよこ豆/"><Image src="/img/top/bnr_piyokomame.png" /></a></Row></Col>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-KIGURU ME/"><Image src="/img/top/bnr_kigurume.png" /></a></Row></Col>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-ゆるあにまる"><Image src="/img/top/bnr_yurunimal.png" alt="ゆるあにまる" /></a></Row></Col>
		<Col xs={4} sm={2} ><Row><a href="/category/キャラクターから探す-La%20Dolce%20Vita/"><Image src="/img/top/bnr_vita.png" alt="La Dolce Vita" /></a></Row></Col>
		</Row>
		</Container>
		</Container>

<StationaryTab />

			 <Container fluid={true} className="bg_gray">
      <Container className="pt-3 pb-5">
<h2 className='ttl_en'>RANKING<p>ランキング</p></h2>
        <div className="category-wrap">
<div className="ranking-box">
  <a href={"/item/"+this.state.ranking1+"/"}>
<p className="ranking-img item-img"><Image src={"https://images.mindwave-store.com/public/"+this.state.ranking1+"_main.jpg"} /></p>
<h5 className="cate-title"><GetItemInfo code={this.state.ranking1} setkey="name" /></h5></a>
<p className="list-price">￥<GetItemInfo code={this.state.ranking1} setkey="price" /><span>（税込）</span></p>
</div>
<div className="ranking-box">
  <a href={"/item/"+this.state.ranking2+"/"}>
<p className="ranking-img item-img"><Image src={"https://images.mindwave-store.com/public/"+this.state.ranking2+"_main.jpg"} /></p>
<h5 className="cate-title"><GetItemInfo code={this.state.ranking2} setkey="name" /></h5></a>
<p className="list-price">￥<GetItemInfo code={this.state.ranking2} setkey="price" /><span>（税込）</span></p>
</div>
<div className="ranking-box">
  <a href={"/item/"+this.state.ranking3+"/"}>
<p className="ranking-img item-img"><Image src={"https://images.mindwave-store.com/public/"+this.state.ranking3+"_main.jpg"} /></p>
<h5 className="cate-title"><GetItemInfo code={this.state.ranking3} setkey="name" /></h5></a>
<p className="list-price">￥<GetItemInfo code={this.state.ranking3} setkey="price" /><span>（税込）</span></p>
</div>
<div className="ranking-box">
  <a href={"/item/"+this.state.ranking4+"/"}>
<p className="ranking-img item-img"><Image src={"https://images.mindwave-store.com/public/"+this.state.ranking4+"_main.jpg"} /></p>
<h5 className="cate-title"><GetItemInfo code={this.state.ranking4} setkey="name" /></h5></a>
<p className="list-price">￥<GetItemInfo code={this.state.ranking4} setkey="price" /><span>（税込）</span></p>
</div>
<div className="ranking-box">
  <a href={"/item/"+this.state.ranking5+"/"}>
<p className="ranking-img item-img"><Image src={"https://images.mindwave-store.com/public/"+this.state.ranking5+"_main.jpg"} /></p>
<h5 className="cate-title"><GetItemInfo code={this.state.ranking5} setkey="name" /></h5></a>
<p className="list-price">￥<GetItemInfo code={this.state.ranking5} setkey="price" /><span>（税込）</span></p>
</div>
</div>
</Container>
</Container>
{/*
<StationaryColmun />
*/}
<Container className="mt-5 mb-5">
		<h2 className='ttl_en'>INFORMATION<p>おしらせ</p></h2>
		<dl className="topinfo">
		<dt><i className="fas fa-info-circle"></i> 2024.11.15　後払い決済手数料の変更について</dt>
	<dd>GMOペイメントサービス株式会社の手数料改定に伴い、当店の後払い決済手数料が11月15日12：00より266円へ変更となりました。<br />誠に恐れ入りますが、何卒ご理解のほど、よろしくお願い申し上げます。</dd>
	<dt><i className="fas fa-info-circle"></i> 2024.11.12　後払い決済　お支払い延滞時の回収事務手数料のご負担について</dt>
	<dd>GMOペイメントサービス株式会社が提供する後払い決済において、お支払い期日を一定期間過ぎてもお支払いの確認がとれない場合、ご利用代金の回収手続きに関わる費用（以下、回収事務手数料）が発生いたします。回収事務手数料の徴収金額と開始時期につきましては下記をご確認いただきますようお願い申し上げます。<br />■徴収開始時期：2024年11月29日AM9：00以降の後払い決済をご利用のご注文<br />■徴収金額：297円（税込）を請求金額に加算（最大3回、合計891円税込）<br />■加算タイミング：お支払期限を超過し、請求書を発行するごとに加算詳しくはGMO後払いの<a href="https://www.gmo-ps.com/customer/" target="_blank" rel='noopener noreferrer'>「注意事項」</a>をご確認ください。<br />誠に恐れ入りますが、何卒ご理解のほど、よろしくお願い申し上げます。</dd>
		{/* <dt><i className="fas fa-info-circle"></i> 2022.09.09　2023年版のスケジュール帳・ダイアリーについて</dt>
	<dd>マインドウェイブでは、2023年版のスケジュール帳・ダイアリー全般の発売予定はございません。お待ちいただいておりましたお客様には、ご期待にそえず大変申し訳ございません。<br />今後もお客様に喜んでいただけるよう業務に努めてまいりますので、これからもご愛顧のほど、何卒よろしくお願い申し上げます。</dd> */}
		<dt><i className="fas fa-info-circle"></i> 2024.01.05　令和6年能登半島地震の影響による配送不可・遅延のお知らせ</dt>
	<dd>この度の令和6年能登半島地震により被害に遭われました皆様に、心よりお見舞いを申し上げるとともに、一日も早い復旧を心よりお祈り申し上げます。<br />この地震の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
		詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。
<p><a href="https://www.yamato-hd.co.jp/important/" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/newsrelease/index.html" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> 日本郵政</a>
</p></dd>
		{/*<dt><i className="fas fa-info-circle"></i> 2024.08.30　台風の影響によるお荷物のお届けについて</dt>
	<dd>台風の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
		詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。
<p><a href="https://www.yamato-hd.co.jp/important/" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/newsrelease/index.html" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> 日本郵政</a>
</p></dd>
<dt><i className="fas fa-info-circle"></i> 2023.12.22　天候不良・年末年始の影響によるお荷物のお届けの遅延について</dt>
	<dd>天候不良・年末年始の影響により、一部地域においてお荷物の 集荷・配達業務見合わせ・遅れが生じております。<br />
		詳しくは配送業者サイトにてご確認いただきますようお願いいたします。<br />
お客様にはご不便をおかけいたしますが、何卒ご理解のほど、よろしくお願い申し上げます。
<p><a href="https://www.yamato-hd.co.jp/important/" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> ヤマト運輸</a> ／ <a href="https://www.post.japanpost.jp/newsrelease/index.html" target="_blank" rel='noopener noreferrer'><i className="fas fa-caret-right"></i> 日本郵政</a>
</p></dd>
<dt><i className="fas fa-info-circle"></i> 2023.04.21　宅急便の一部区間における「お届け日数」と「指定時間帯」について</dt>
	<dd>ヤマト運輸の宅急便の配送にかかる日数について、2023年6月1日（木）から下記区間でお荷物のお届け日数と指定時間帯が変更となり、最短でのお届けが出荷日の翌々日午前中以降となります。<br />詳しくは【<a href="https://www.yamato-hd.co.jp/important/info_230417_1.html" target="_blank" rel='noopener noreferrer'>ヤマト運輸 <i class="fas fa-external-link-alt"></i></a> 】へご確認ください。<br />
【対象エリア】東京都、神奈川県、千葉県、埼玉県、茨城県、群馬県、栃木県、山梨県、新潟県</dd>*/}
		
		{/**/}
	{/*	<dt><i className="fas fa-info-circle"></i> 2021.09.01　明細書同封廃止のお知らせ</dt>
	<dd>2021年9月11日0:00以降のご注文分より、明細書の同封を廃止させていただきます。<br />当店では、環境への配慮、お客様の個人情報保護の取り組みのため、今まで商品に同梱しておりました『お買上げ明細書』のペーパーレス化を実施させていただくこととなりました。つきましては、当店からお送りいたします受注確定メールにて、ご注文内容をご確認くださいますようお願い申し上げます。<br />
	お買上げ明細書をご希望の方は、PDFデータにてお送りいたしますので、お手数ですが、ご注文の際の備考欄にご記載くださいませ。<br />
会員のお客様は、マイページからもご注文履歴をご確認いただけます。</dd>*/}
		<dt><i className="fas fa-info-circle"></i> ご注文前にメール設定をご確認ください</dt>
	<dd>ご注文前に、必ず「＠mindwave-store.com」のドメインからのメールを受信できるよう、ご設定のご確認をお願いいたします。<br />gmail、hotmail、yahooメールなど、フリーのメールをご利用の場合は「迷惑メール」フォルダに自動的に入る場合がございます。また、携帯電話のメールアドレスの場合、なりすましメール拒否等のセキュリティ設定で受信できないことがございますので、ドメイン指定受信設定をお願いいたします。</dd>
	<dt><i className="fas fa-info-circle"></i> 明細書は同梱しておりません</dt>
	<dd>当店では、環境への配慮、お客様の個人情報保護の取り組みのため、『お買上げ明細書』は同梱しておりません。つきましては、当店からお送りいたします受注確定メールにて、ご注文内容をご確認くださいますようお願い申し上げます。<br />
	お買上げ明細書をご希望の方は、PDFデータにてお送りいたしますので、お手数ですが、ご注文の際の備考欄にご記載くださいませ。<br />
会員のお客様は、マイページからもご注文履歴をご確認いただけます。</dd>	
		</dl>
{/*		<div className="mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202106/bnr_tana_sp.jpg" />
	 <Image src="/img/202106/bnr_tana.jpg" alt="休業のご案内" fluid />
</picture>
</div>*/}
<Row>
{/*<StationaryInfo />*/}
<Col xs={12} sm={12} md={6} className="mb-4">
<a href="https://cosme.mindwave-store.com/" className="opa06"><Image src="/img/top/bnr_cosmeshop.png" alt="コスメストアTOP" fluid thumbnail /></a>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4">
<a href="https://images.mindwave-store.com/mz/ncolumn/column_top.html" className="opa06"><Image src="/img/top/bnr_column.png" alt="オンラインストアコラム" fluid thumbnail /></a>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4">
<a href="/member/" className={this.state.cognitoid ? 'opa06 membersnone' : 'opa06'}><Image src="/img/top/bnr_mailmagazine.png" alt="メルマガ登録はこちら" fluid thumbnail /></a>
</Col>

</Row>
</Container>



</main>

				<Footer/>
					</div>
		);
	}
}

export default withRouter(Default);
